import "../../component/src/components/member-components/invite-team-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/member-components/invite-team-modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61U246bMBB9z1f4pVLzYARpkm6dj+gnVAYPMFtjI3tgYav+e4UDDdAERdU+MozPmXPmEv1I4tf3rqKY/doxlltD/A2wKEmwcxxfppjHdxCsle4z5zLP0QAP8ZKf90NSJV2BhqeWyFaCHeK6u+x+76IJPgnwq6xknXUIWQp9rWUvWK6hG8BfG0+Y9zyzhsDQ9QcHoxaPv8wpNOQk2LHuZsW5q6wrK2OZ1datNLUHTtARrx1W0vX7BcHxyeoyMARu+CU1FoYjQeXn4ULWgp0XtZGtBTut7DgtCdHo4Pt/876honJqDmPl2Ofpe7PRnd8/bxpjqXUKHHdSYePHRsyVidK24IK+VGY/C2cbo/7F1bIHx28ZPDz73oLTctWdUyQzwhYC5laZUNWl9DjqaZwfMhXkstE0hGqLg2EcWjDkBTPWwIpJyxT0FhFm1iz8mHmbnNdmRAq9TDWoAGlrmSH1gkXHBxVuqRuh9s8pYTJQjoORxPGny0eM+IzifH9pwgYrdJARWiMGSU1l/i5Hclh59PV2nh5NqK9uTk8n7DScsBnMy/1qtvZ0PbrfPqzvcgvJk6TGc3DOujXSg+2cQacBupZKoSnGYxiO+RQab+HSney+O8GGl1D8H7d+p4kvBgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentStyle = '_10jzxmtb';
export var errorHint = '_10jzxmta';
export var iconStyle = '_10jzxmt9';
export var invitationLinkContent = '_10jzxmtc';
export var inviteModalButtonContainer = '_10jzxmt2';
export var inviteModalContent = '_10jzxmt1';
export var inviteModalTitle = '_10jzxmt0';
export var inviteName = '_10jzxmt3';
export var modalContent = '_10jzxmt6';
export var modalSubTitle = '_10jzxmt7';
export var pageItem = '_10jzxmt5';
export var pagination = '_10jzxmt4';
export var radioItem = '_10jzxmt8';