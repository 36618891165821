import "../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UzY7aMBC+8xSjlSrBwShh2e02XPoqE3sSXBLbsgfItuLdqzhhE1NYaW89EebP38/Ya61yDK8Z/FkA7EnXey4gz7JvuwXAWSveT3+VDq7B9wKqhro+0P8KpT1J1tYUIG1zbE3MWMMi6N9UwAn9UgisKm1IxHiJgVZ9lbNBD52eGmR9oj5aojzU3h6NEtI21t+MmKWd1y3696FsddM7nC7tifxucVmsB6J5JDrO1WZPXvMsvYlptq6ArJ/XUMXjpx+0ye4qMTGpdEeqD2GjayM0UxsKkGS4xwHw6xhYVz1mw2S4gOBQkiiJz0RRO4dKaVMXkL+6DvJN5roZwufEqZeN6+44dQeOOFN50CzQOeGpjknlsZ6N3sbR/3AbXBU1ugK2CZaXuZiJSUwd37Hn07UI7VRzvvLLot5xnCJpPQ60jDWUaLXtpXpL0L1GdLMjN1s3mqV7NwSdyHAoAI9s531r16A2X+P20f0j9k1+nM679Gad9gnwLF3bLy279Yq88Kj0MVwnxdgkUGk7Efao7Pka+0CK/497+cZ1MCw6QIudeLjPyUNxhz97NNfaSTzI1s8BCEPCv8CKyY8qjLfx6Sk9D8tgmyOP5zHb9vYJKBsrD7M7KLGRyx44CNhmrlvN/c/WLwPJx443KA8iz1YJUmN5WTQYWNhK8LujVYTdoq+1ETNgl8XPlpRGCNITGUCjYJko2t+CoTt5827fnasbl6lwOxZ+UL/6OKt5e1xzH5o2n0D7/mjaI4sui8tfoSZqXdIGAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = 'id1as61';
export var draggableHeader = 'id1as63';
export var hideInSmallScreen = 'id1as68';
export var hideInWideScreen = 'id1as67';
export var iconButton = 'id1as66';
export var menu = 'id1as69';
export var menuItem = 'id1as6a';
export var root = 'id1as60';
export var topNav = 'id1as62';
export var topNavLink = 'id1as65';
export var topNavLinks = 'id1as64';