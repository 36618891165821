import "../../core/src/desktop/dialogs/setting/workspace-setting/new-workspace-setting-detail/members/styles.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/new-workspace-setting-detail/members/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVyW7bMBC9+yuIAAWSAw1ZtV2HufUHeugHFLQ4sqahSIIcbyn67wUpyVoSxyianiQus715bzj/sTzo48tjxn7NGHM2IKE1gnnQkvAATzPGrJMF0lmwbL6M61r6HRpO1gmWL93pafZ7Nu/8CCFLAp/cFdYQGBLs7i7aHVFRJdgiyz7FZQW4q6hf98HlNli9pxRcQ0mCZfE3BUx/xd4H6wUzlrjU2h5BDZNYNMVIpdDsBEspMra1XoHnXircB8EWebsti+edt3ujBDtIf8+5LEs0wA851/IMnvc3uPNYS39+6P0JtnAnFqxGdcUcTUAFX5vojVGyVxiclmfBSg0pk/jlCj0UDQyF1fvaxJOf+0BYnvkF0OBkAXwLdAQww9LzVPrFNRodc+kiSI07w5GgDoIVYAj80PhzMi6tIR7wBSZ4pP1TSLkXVkf8pwUTnIhD7SoZsL3YdspZbKJd6NM0tu3NJOFhUsuWSm8GDCRpHzh4byOovdVqjEMHwCsgOxAm2MT730l6ulVsgMIa1VFiJ92lpAv9Ns36Nqx99usxgbPok2VssW5cdcpZbSbodWVOlHaj7WtR2UMr2b9SQzL7dgCv5VASF4ltxrNhLYyle6FlIG5LTmcHDyloy4itJbK1YOux2Zd0py0pX2VXar6unnQSKo/muZ0fIwp2g6Dd9A22eTbOYtNoI7raeXsUbJFG4wF8qeOyQqUghUu86A9Aa3QBQ+pLhQQ8iTcOr6OX7iY1Qj2ixmbuwERavCeLqQ5788fbEk8B33c9HIMfA0EaUx2v83yMvfyQuTSW6v9Ie0KZ7dsz6DpR0/jo6Nirf+iz+Cefw5d7steJb1qEevWWtrL+AzcqwoU6CAAA\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var confirmAssignModalContent = '_4vlwz9b';
export var confirmInputContainer = '_4vlwz9c';
export var errorStyle = '_4vlwz94';
export var fakeWrapper = '_4vlwz90';
export var goUpgrade = '_4vlwz93';
export var goUpgradeWrapper = '_4vlwz92';
export var importButton = '_4vlwz9d';
export var memberContainer = '_4vlwz97';
export var memberEmail = '_4vlwz9a';
export var memberListItem = '_4vlwz96';
export var memberName = '_4vlwz99';
export var membersFallback = '_4vlwz95';
export var membersPanel = '_4vlwz91';
export var roleOrStatus = '_4vlwz98';