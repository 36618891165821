import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81X7W7jKBT9n6dAlUZqpVI5mdTtUI20b7APUPUHNtc2WwIIsJPsqu++MviLJE6Tzqy0P9o6xPdyOfecc+mDaJZJphL0zwIhxq0WdE9QIWD3skBowyWugJeVI+j7Svs1rSx3XEmCDAjqeAN+lTLGZUnQSu9Qonf+bxfRZsNbQzVB7e+Xxcfiodv2lRlaljQT8PPGmRpu3nwheW2sMgSVhmbnX39l1FHsl7ksoyQNtzzjgrs9QRVnDOT5VCSDQhkIBSjpQDqCbm5epsBkQuXvMQo0s0rUzqMQ142QU5qgx+Rb+yygcAQl7WOmDAODDWW8tqSHKaP5e2lULRnOlWjTNNTcYkyLgkvAzQo72DmsBc2hUoKBufN7GCptocyGhEdBHdziVO/uEX5Mvo3vdPWGfqLkYWVR8rC090hpmnO3H5fakG6xq7gnwTqUuuXMVZNPQuC8orKEPv2Q9BPIaeHA/I8Q90BpakC6eWyfI2x7aJZJ2LXD5vmXsalUA2ZKymGnrvyhRctJpqV/tQ0thNqOzB/DE32k7W5pEHH3+ZSC2kZw6cBgaEA6S5BUEiYFrE5biTcBxg3koYm5EvXG5/urto4XezwwwDpq3LzVHPSyI2GhpMOW/w0Hsgnrm7vofKneDT/riUnZynD53nFGtOE9QqsO89qCwRYE5K4/ecvdeblayJVk1OzvJuRYph7hEbNgYxW1eAOyPumFHeyfRwXi+NjPLUXQPRg8ec8H/9mAEbQt+WMxOizWBhoO2zfUF3B2q0hLQ9HfL6OHUdt2mQpeSswdbCxBOQQAECrbWZJGEK5P551JcMS58avTVjGh1zINSzOI8lzJqOtDiY++RH/S0rTaXL7MSNUzZ/wChODacu/L24o7wFbTHFoChoF6Cf2HMtKvdyCI5L+S50nBTTXblxk7XkoKldcWe7cSwSpV7dpsR+aUEjKZn93guXTWjlmCJgxQpqTY/7wpqLDDZcRrYvZKcyzjK7MRbm+D7u5Rd/ItdxWXd79N8l1hTz5hIIAFURz2/5KeXm6N4/DpWz3cPNMJh7b9TSRJZpzgCdmmDFL7DaJ96jyuz3kugTZ8cxD+fO009Ad6jg704ypr8wlWhxN9Habd0aU+nkP0+p3WB7I8ZWhD/sznH4Zg3OZl+iWrPQF6ftUxLqFxRL3HQL0ZJz5z/DxIOldCUG2BxQZxoVKGbMyHTa/2VIhwiQdqAXOJVe0uhy9CYhUTg52rfHJLNsq1V+QfCYPynP7n9QZfEkzHwg01JZfY/xOwjg4Ar6Ebb3H6fjz8sQHGKdKGS+ffiCzwKAChj8XHv89d3Lg7DwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = 'lv10bo1';
export var propertyIconContainer = 'lv10bo4';
export var propertyNameContainer = 'lv10bo2';
export var propertyNameContent = 'lv10bo5';
export var propertyNameInnerContainer = 'lv10bo3';
export var propertyRoot = 'lv10bo0';
export var propertyValueContainer = 'lv10bo6';
export var section = 'lv10bo8';
export var sectionCollapsedIcon = 'lv10bod';
export var sectionContent = 'lv10boe';
export var sectionHeader = 'lv10bo9';
export var sectionHeaderIcon = 'lv10bob';
export var sectionHeaderName = 'lv10boc';
export var sectionHeaderTrigger = 'lv10boa';
export var tableButton = 'lv10bo7';