import "../../core/src/components/workspace-selector/user-with-workspace-list/workspace-list/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/user-with-workspace-list/workspace-list/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VT0XLbIBB891fcS2eSBzxIcdKEfEwHw0m6FgEDyJaTyb9nhK1GluU0nTxywO7e3t76VxFfTPdUc3hdAWiK3siDgMpg/7wC2JNOjYCC8x/DcSgzTQFVImcFKGe61g43rexZg1Q3SYCSRt0UnO8aYHDHue9vn1dvq/XIVXyTq5ZeQOn7M9ByGfR3FxNVB6acTWiTgOilQrbFtEf8QNv4/NpLrcnWzGCVBBTlsdrKUJNlW5eSa09vJ8x3y8zX9StnXBCwk+GGMVlVZJHtSpawTyyiclbLcLida5vZ43YYKuP2AhrSemhlImmzLEkaqi2jhG0UoNAmDHOWytnE9qdB3nP+txbpBWeac72PWakZCmMASn4Eyx19CEVjyEeKuZuGErI8DAHW7YP0Zx3c5w6+wnxpxIVX39DxkHVcmRgpZ5kP1I7zmugtHmYx+ZmRTsrmGXrMl6N/m/M4CuCnLE5+PP3XiC/WIKczJhnSGdGj76E4sedYjAfVhThY4B2NmFsXNAYWpKYu5q/Z6yBtpGPkt1L9qYPrrGbZQeDrMs58KkeCTyyeLsXEAvnvjMR2IZ3lp4wLa3i2FJvjUiwGZzmNV+P39g5dL7+hfwUAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var ItemContainer = '_1szlu9g9';
export var ItemText = '_1szlu9ga';
export var account = '_1szlu9g5';
export var scrollbar = '_1szlu9g7';
export var workspaceCard = '_1szlu9g8';
export var workspaceListWrapper = '_1szlu9g1';
export var workspaceListsWrapper = '_1szlu9g0';
export var workspaceServer = '_1szlu9g2';
export var workspaceServerContent = '_1szlu9g3';
export var workspaceServerName = '_1szlu9g4';
export var workspaceTypeIcon = '_1szlu9g6';