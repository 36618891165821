import "../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41V227bMAx9z1cQBQY0wBQ4btq17goM+4F9wLAHxqIdbrJkSHKcbMi/D5Yd13adpk9GKF4ODw+Z1ZYKV+8j+LcAqFn6XQLrKPr0vAAo8CA60x7trRCYZaxJkGRvbPu0bB1tzlooynwCWHkzMFrOdwNriVKyzjvfubSOJYnO7TPEm+UwrMv24bjMaC8c/6VJTLBv0dHyeXFarFoS1oGEHbU11g/l4fktKZJdqfCYQKYovP+unOfsKFKjPWmfQErak22eUHGuBXsq3Ku5LxeHcltM/+TWVFpOIG6NlWRFapSxoZczsGh1P4OsT3sX0l6H6UpMSWzJ10T6OtpNSBt4qzsg91E04XjdIVNNA2e88aY1hk4mTXo6eFFaLtAe+1b7mvejgcSPbZ6OGIuSK5fAyJrAujyAM4rlFTZfeRdzwOodewqOAWLgZjjaYdPxXNMdrDnCPkwEQFpZ1/iWhifzeAjcdEu2Nd6b4qzZ3udL8LlYzlFqtBwXPOO/m2uqNfbpH+eORu8btQneCHFWZgA5lgl0SgnkiyDQBLSpLZajsquuC0sZWdIpCc9eUbtP7aDPjGijaRD7NL8czVdItpR6Ns2cjaoKPYjDEDc+HxkWrI5zh8Whdt3z8gPa3MdC4ZGsYN0cse/nDprP8rLiu1vX/55o4fHy1u3jkd6GK4fJzuzJTk7T7Ir0sAd+IfjHnqzCUdptq8SLak7Du7eoHbcjQKUgWsUOCB0J1sJU/p1+ODV60M9oQeNoJNv0p0SPTUsKS0fy5cbbim5+vULIjC0SsMajp9unSFK+vFa636XQ9beCJCOUlrUPaVfDv9mBAFt5ApyaoOY0I2uysGeqS2M93Ibtgq8v8LCJysNyLlmvg6g/tafF6T/vtS0e2QcAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'bemswvc';
export var container = 'bemswv0';
export var divider = 'bemswv2';
export var dividerContainer = 'bemswv1';
export var link = 'bemswv8';
export var linkPreview = 'bemswva';
export var linkPreviewContainer = 'bemswv9';
export var linkPreviewRenderer = 'bemswvb';
export var linksContainer = 'bemswv6';
export var linksTitles = 'bemswv7';
export var showButton = 'bemswv5';
export var title = 'bemswv4';
export var titleLine = 'bemswv3';