import "../../core/src/components/sign-in/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/sign-in/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VUy3KjMBC8+yvmuHuQC4ixs8pt/0TAgLUREiWJ2N5U/j0lgYmwRYJPqhrNq3t6ZlseesmKBN43AC3TDZfEqo7CU9KdXzYfm+3gkC44AJRKKE3hjelfhLC65hKJxbMlBkslK6YvxLv8ds61kpYY/h9vArz9bLyPcIYj8uZoKaTbfNYF833EagouX6dKQcTWfQxRvTYurFNcWtRLzccTZQEBFFhv1csXIYWyVrUUdgMlHj4TvJEUShxqTYmefKKKm06wC4VaoI/51xvLa8eVtCgtBdOxEkmB9oQoI7VuBrRbRwxAwcrXRqteVhSsZtJ0TKO0/kvpCjUZk9x8Pjy6LBvY6A1qYlBgaSlIJTHoOvddXyMOhyHijhz3koprLC1XjlQl+lZGabvyDeAHQLjF1oTmb0S+Xzmar2QN6yg8D11HwX/LmmlDhR0WJ+j11GneRpbpNBbcJ0mQ69nnOvHKHins8hWy/HO34Om44KNNYG0pkHxmvIox3S8fg7dsAOB0GOJdXuWliCI+Hz+EdCR8YerTXOagBqBZHFM2E0fpi7uaFNIwZRKszhTbncEowas7YIJdUBMuDa/w7zXIPSHQ6kdqpuO68qxOqfHH1KPQVt/rmQzzmQzrtSN+CELzsHCOceEsn5Woij4+AalZPOItBwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addSelfhostedButton = 'c7unab9';
export var addSelfhostedButtonPrefix = 'c7unaba';
export var authMessage = 'c7unab1';
export var authModalContent = 'c7unab0';
export var captchaWrapper = 'c7unab2';
export var linkButton = 'c7unab4';
export var passwordButtonRow = 'c7unab3';
export var resendCountdown = 'c7unab8';
export var resendWrapper = 'c7unab5';
export var sentMessage = 'c7unab7';
export var sentRow = 'c7unab6';
export var skipDivider = 'c7unabb';
export var skipDividerLine = 'c7unabc';
export var skipDividerText = 'c7unabd';
export var skipLink = 'c7unabf';
export var skipLinkIcon = 'c7unabg';
export var skipSection = 'c7unabh';
export var skipText = 'c7unabe';