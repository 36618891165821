import "../../core/src/desktop/dialogs/setting/workspace-setting/billing/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/billing/styles.css.ts.vanilla.css\",\"source\":\"LnF1cXNjbzAgewogIG1hcmdpbi10b3A6IDI0cHg7Cn0KLnF1cXNjbzEgewogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogMjRweDsKfQoucXVxc2NvMiB7CiAgd2lkdGg6IDEwMCU7Cn0KLnF1cXNjbzMgewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1zZWNvbmRhcnktY29sb3IpOwogIGZvbnQtc2l6ZTogdmFyKC0tYWZmaW5lLWZvbnQteHMpOwp9Ci5xdXFzY280IHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiAyNHB4Owp9Ci5xdXFzY281IHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgbWluLWhlaWdodDogNzJweDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwp9Ci5xdXFzY282IHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjsKICBwYWRkaW5nOiAxMnB4OwogIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLWFmZmluZS1ib3JkZXItY29sb3IpOwogIGJvcmRlci1yYWRpdXM6IDhweDsKfQoucXVxc2NvNyB7CiAgZmxleDogMSAwIDA7Cn0KLnF1cXNjbzggewogIGZvbnQtc2l6ZTogdmFyKC0tYWZmaW5lLWZvbnQtaC02KTsKICBmb250LXdlaWdodDogNjAwOwp9Ci5xdXFzY285IHsKICBmb250LXNpemU6IHZhcigtLWFmZmluZS1mb250LWJhc2UpOwp9Ci5xdXFzY29hIHsKICBmb250LXNpemU6IHZhcigtLWFmZmluZS1mb250LXhzKTsKICBmb250LXdlaWdodDogNTAwOwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1lbXBoYXNpcy1jb2xvcik7CiAgY3Vyc29yOiBwb2ludGVyOwp9Ci5xdXFzY29iIHsKICBtYXJnaW4tdG9wOiA4cHg7Cn0=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var billingFrequency = 'quqsco9';
export var billingHistorySkeleton = 'quqsco5';
export var cancelPlanButton = 'quqscob';
export var currentPlan = 'quqsco7';
export var currentPlanName = 'quqscoa';
export var history = 'quqsco1';
export var historyContent = 'quqsco2';
export var noInvoice = 'quqsco3';
export var paymentMethod = 'quqsco0';
export var planCard = 'quqsco6';
export var planPrice = 'quqsco8';
export var subscriptionSettingSkeleton = 'quqsco4';