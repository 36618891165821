import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227jNhB991dMAyzgAKEhK443kbHAop/Q9iFFERQjaWQzoUiVpGU7Rf69ICnbknxZe5E+SZjbmTMzmtH3N9oUGksy8Pc4K6f/bF4n8O8AoNCq9C8AqsKM200C0WwA8DEAsKqvGgfVx+D7YcCH8wEBrEZpCqXLJLwKtPTnkMVfbsFkKGgYjZ6mtz8APxUm2gYZ357JcepjHob+qQyPUf2EHL/263g82DiKvhwW6xTwGbzHy/CiS8FaiR3Fe7oE7/kKfs/n+eFleBfye+7yG21RyPtUynDLlUyg4GvKnRWXhmwzYSlmb3OtljJnmRJKJ1CjHjKGRcElsZa6VDmKYOSx3hmXOa17Do00WHs7lLzEkMH+Qx8/RKWBQukV6tzMOmmPnAeTSgYCLX8n69mWKuUiGP6ASh0zgRvSXU7Oe5dsK3JxSfFybiqBmwQKQWvPVfC5ZNxSaRLISFrSTvy6NJYXG5YpaUnatuqyKrYT+ytHi03mL6FCbVSXCiPpc60wz7mcs1RZq8oESNZDgwUx1ITMM2l0dxBH1fooUrEUgplME8lvN1Yv6SagNsETiOAXXlZKW5S2G+CKTja2Beb0u1tIf6iq77Pf6mF6smXKM5bSOyc9jEaT+A6iO4hGD493MO6OHiu4EK6alHSGLjDcBr5/gXP5jGrLMLO8DmxqTivmP0I/IkxiSfsk02PsjOA5/eoLforcV4hG8b0BQkOfQ6EFehWD7CSD3/h8YU8RePp/CHjMq/LPXfAk6RspkQ93Tbo9xWL6eTN2PoXsZAqP1xbyPFB+EgivBdo1aO5Dsl3zogRmHcG4L4iDYMVzu9juvJ23r+eCXKf7ulDrkkt2XB8HPa53+gxF5g5jXi+AwX3st1swadD3Fitg2/0HkKo1M/zdr7ZU6dxdDOW3e6GkdSrqbWsvT9HQ7c5q1WQxifyhEM5sm9l4NJ2196dDhnhSeYz9xdEk0I36dVda1aQFbliFktrX2rNaYK5WPd9KVc6nUTa2nrXGnC9NAuM4pKaW1vHYL+/T9+HchISKH8xJaNXBtOzFrc7tQ7Q7vjftEYg6Q7voXXZMjRJL6+tsVZVA3PDVIaxrz09dah7+8s4NzYJND2dmenRmJg+Br55zuTvooTUtzFePuVI6Z6kmfEvAP5iT+A9vwS0xU2FGCVSa2Epj5Ztbky6EWnlB12vbaE/x202BwtDNy8GfEne/NIxqktb0Z6Tr2h+Pvi8urZoNPv4DMj6iHCIOAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyjh';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjg';
export var modalContentWrapper = '_1cm6qyjf';
export var modalDescription = '_1cm6qyjj';
export var modalHeader = '_1cm6qyji';
export var modalOverlay = '_1cm6qyje';
export var modalVTScope = '_1cm6qyj3';
export var widthVar = 'var(--_1cm6qyj0)';