import "../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51TyW6DMBC98xVzqdQeHAGlWZyPqYw9EKuObRmTQKv+ewVZDKRtlpOlWd4y45m9Z3G84kkMXxGAZUJIXVKI1xGA2aErlNmTlgKrveliOeMfpTO1FoQbZRyFHXPPhLCikBrJLiWKtejIoK5CbrRgrn1ZR9/R7EiYjAmT1DbDdNqn/6Dw2HhindweQAGErKxiLQWpVVdTKGy6OFOy1ER63FYUOGqPrguXzFJYjglfe8Iz0M0It4sMwz11blCWG08hy8ZasvFs0sw2kMwPPRcKu5cI6ZB7aTTt5NRbfRZ56AvYb7/7vIKSThTO/1tPXntv9MB76FuMnU1Ql312L4XfUEji+OlySACF0Z5U8hMpJAs7WFKFqgg7Cqirhxwf2UZiAia7+j09Oi8vVj8xnPcw4VZuuKbhj8qNE+iIY0LW1emGpmz3W59cBn/4Ms5ClrY5Wv8BGgK7hXAEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomDoneButton = '_4009c18';
export var bottomDoneButtonContainer = '_4009c17';
export var doneButton = '_4009c16 xn1iiob';
export var group = '_4009c19';
export var groupContent = '_4009c1b';
export var groupTitle = '_4009c1a xn1iiom';
export var pageContent = '_4009c14';
export var pageModalContent = '_4009c10';
export var pageTitle = '_4009c12 xn1iiob';
export var popupContent = '_4009c15';
export var popupModalContent = '_4009c11';
export var popupTitle = '_4009c13 xn1iiob';
export var rowItem = '_4009c1c';