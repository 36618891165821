import "../../core/src/components/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB95yumK1WCbr1K2OzNaPsdlSpUmXgS3HVsy3EgabX/XtkBkkBAS6U+AGLuc87MJHc/4zensyb6wZljBAvjmldnK1zCnwlAqqW2FDbMTglhWSYUEiNZimstOVoS9LPF5H1ytwsUBz8uSiNZQyGTWC8mEH4JFxZTJ7SiPnBVKK/ZCu7WFOIo+uz/5sxQiOem7gedh6BBNY+GqvvxfMH20XS5t9ZL/PdJ0i5YEoIZxrlQOYXImxpdirZmi5I5sUEvddpQSJlMp+T+7sHUcLsDyTIuamK0MWgJU+laW7JGka/dDL4AiWfeW2LmKLSevXJCvPNhgtUMbiF+NHWIozdoM6m3FNaCc1T9Xh4uAJOcBebQexyZescDwIqlb7nVleJkbCLWvo7DLAAUQu16ppCcUDkscKWtHyTfb1XSj2bczIlkDVrSszNWFMw2g2l8DDlCn7n1OMWnTQaWC2ZzoSiQTtRWRkFpFSjXlZNCYSfItHKkFL+RQrxH1IsyVgjZUBBqjVa4RbdHPclpg84yVRpmUblBC5T2Nu4ft/Lp2q0MY/LcNtXtg5fsPy1qdUd0FLXCbihZ5fQ4908ji9YP1kLceT6P1/+rKp3IGpJq5VA5CqVhKZIVui1i6INJkSsiHBYlhRSVQ3va0hGZA1yDvC5nB5vtrsSHKFqc4cJh7UiJqVac2WaEjg6ItrGLycti4PsSPPYgk7rb/XO3qg/sfM9SR/BYWS//bTd3maMBC8npOB1PAPvYBFu9vYr2fbr75BgWgLSype/OaLEPcIRIMsSPtQ/RTKdVibz3GL3qfo7x0XZ/WLXkHG9HFdz4Em6WI85XHKBVO3CGpcI1gbn3CRXl9JCThvq/HoqYz+CMbzyI29ZaOubwVRtUywvG6cgh77R8eE7CDf/IXofVumqHjydmjAR+kawsaAPWmbbFDnbJHH6fJuG53tnm157t0VU/f5DzloL2ZaNMrZaSMIuMbARujbZuCd+Ai82wDMdWEuGTKLwFC8PyFz3YTSVHCgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_1ktofy3';
export var menuItemList = '_1ktofyg';
export var menuItemListScrollable = '_1ktofye';
export var menuItemListScrollbar = '_1ktofyf';
export var searchInput = '_1ktofy6';
export var spacer = '_1ktofyc';
export var tagEditIcon = '_1ktofyb';
export var tagSelectorEmpty = '_1ktofyd';
export var tagSelectorItem = '_1ktofya';
export var tagSelectorTagsScrollContainer = '_1ktofy9';
export var tagsEditorRoot = '_1ktofy1';
export var tagsEditorRootMobile = '_1ktofy2 _1ktofy1';
export var tagsEditorSelectedTags = '_1ktofy5';
export var tagsEditorTagsSelector = '_1ktofy7';
export var tagsEditorTagsSelectorHeader = '_1ktofy8';
export var tagsInlineEditor = '_1ktofy0';
export var tagsMenu = '_1ktofy4';