import "../../core/src/desktop/dialogs/enable-cloud/dialog.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/enable-cloud/dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61TQW6DMBC85xV7qZSocgSEpKlz60cqxzawlbGRcRJo1b9XOKAaQlpV6gnZuzszO4zXrzFvRWPSCD4WAALrSrGWQqZkc1iA/xKBVnKHRlPgRp1K3VXeTrXDrCXcaCe1o8CldtJ2JaYw1wSdLOvwmhtlLIUzs0tCWJahluScECcbRyqLJbPtquurmBCocwrxrmoOi8/FehAZe5EXFK6gsPFVgEJiXrjv8x0a5EYHNAFq4lEzox2p8V1SiPdXJNWNDvBJD+/l+g3D3Upmc9TEmWqQ3SNe+vFdFI1IN550Huwno2rJjRaDVT3t0ThnSgpJNDEsDQ2Lo+jhMNk0HXuYJhOArQcoWUMG17fTll3f0imhEAEZ9u8nOFN82XHDI2ySqvHCj8YKaSnEVQO1UShutlWslZagrlHIF99NrkPjjES3KXn6a5Tv5HViW86qGYf3/0Q2l4SA5nma0ZFd/r4gu9V87GaTHICz38GPrJa36Okcenr/GY4TTHzL+C0ebwMbVPlcnMOn1/+eL0XsFtHWBAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = '_1cydxo4c';
export var desc = '_1cydxo43';
export var description = '_1cydxo4a';
export var dialogContainer = '_1cydxo40';
export var mainButton = '_1cydxo44';
export var mainIcon = '_1cydxo41';
export var mainTitle = '_1cydxo42';
export var modal = '_1cydxo45';
export var root = '_1cydxo47';
export var serverSelector = '_1cydxo4b';
export var textContainer = '_1cydxo48';
export var title = '_1cydxo49';
export var workspaceSelector = '_1cydxo46';